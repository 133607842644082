var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-wakeup-message-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(!_vm.module.model.config.conditionsExists),expression:"!module.model.config.conditionsExists"}],attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"conditions-types":_vm.module.conditionsTypes,"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'wakeup_message_interval',
          'step': _vm.StepUnit.Minute,
          'hasAnchor': true,
          'settingWrapper': {
            disabledValue: 0,
            hasFieldButton: true,
            customiseValue: _vm.StepUnit.Hour
          },
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'send_smile_message',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'send_wakeup_message',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),(_vm.module.model.params.send_wakeup_message)?_c('nested-content',[_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'wakeup_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'maxMessagesCount': 24,
            'hasMedia': true,
            'disabled': _vm.disabled,
          },
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }