var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('switch-input',{attrs:{"setup":{
    'func': _vm.newConfigInputSetup,
    'args': {
      'model': this,
      'key': 'isEnabled',
      'hasAnchor': true,
      'prefix': ((_vm.module.snakeCaseType) + "_"),
      'disabled': _vm.disabled
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }